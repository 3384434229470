import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  Container,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import inkwellLogo from "./images/inkwell-logo.png";
import { WhiteButton } from "./components/InkwellWidgets";

import Home from "./views/Home";
import Writer from "./views/Writer";
import AuthDialog from "./components/AuthDialog";
import theme from "./theme";
import {
  isSubActive,
  createCheckoutSession,
  createStripePortalLink,
} from "./payments/Stripe";
import { getFirestore } from "@firebase/firestore";
import { firebaseApp } from "./index";
import AccountSettings from "./views/AccountSettings";
import Plans from "./views/Plans";
import Private from "./components/router/Private";
import { useDispatch, useSelector } from "react-redux";
import { resetUser } from "./redux/reducers/userReducer";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MobileDeviceDisclaimer from "./components/MobileDeviceDisclaimer";

const responsiveTheme = responsiveFontSizes(theme);

export default function App() {
  const { currentUser } = useSelector((state) => state?.root?.user);
  const location = useLocation();
  const { pathname } = location;
  const analytics = getAnalytics();
  logEvent(analytics, "app_open");
  const dispatch = useDispatch();
  const [authDialogOpen, setAuthDialogOpen] = useState(false);
  const [authDialogMode, setAuthDialogMode] = useState("login");
  const [openMobileDisclaimer, setOpenMobileDeviceDisclaimer] = useState(false);
  const [user, setUser] = useState(false);
  const [subActive, setSubActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const db = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");
  const handleLogout = () => {
    getAuth()
      .signOut()
      .then(() => {
        localStorage.removeItem("user");
        dispatch(resetUser());
        window?.location?.replace("https://inkwellpr.ai/?logout=true");
      })
      .catch(() => {});
  };

  useEffect(() => {
    const listener = onAuthStateChanged(auth, async (newUser) => {
      if (!newUser?.email) {
        signInAnonymously(auth);
      } else {
        setUser(newUser);
        localStorage.setItem("user", JSON.stringify(newUser));
      }
    });
    return () => {
      listener();
    };
  }, []);

  useEffect(() => {
    if (!auth?.currentUser?.email) return;
    isSubActive(db, user).then((active) => {
      setSubActive(active);
    });
  }, [user, db]);

  useEffect(() => {
    if (isMobile) {
      handleMobileDeviceDisclaimerToogle();
    }
  }, [isMobile]);

  const handleSubscribe = () => {
    navigate("/plans");
    // createCheckoutSession(db, auth.currentUser)
    //   .then((checkoutSession) => {
    //     window.location.assign(checkoutSession);
    //   })
    //   .catch((err) => {
    //     console.log("Error creating checkout session: ", err);
    //   });
  };

  const manageSubscription = () => {
    setIsLoading(true);
    createStripePortalLink(firebaseApp)
      .then((url) => {
        window.location.assign(url);
      })
      .catch((err) => {
        console.log("Error redirecting to stripe customer portal: ", err);
      });
  };

  const handleAuthDialogClose = () => {
    setAuthDialogOpen(false);
  };

  const openAuthDialog = (mode) => {
    setAuthDialogMode(mode);
    setAuthDialogOpen(true);
  };

  const getMode = () => {
    return authDialogMode;
  };

  const handleMobileDeviceDisclaimerToogle = () => {
    setOpenMobileDeviceDisclaimer(!openMobileDisclaimer);
  };

  return (
    <ThemeProvider theme={responsiveTheme}>
      <ToastContainer autoClose={3000} position="top-center" />
      <CssBaseline />
      <Container>
        <AuthDialog
          open={authDialogOpen}
          handleClose={handleAuthDialogClose}
          getMode={() => getMode()}
        />

        <Dialog
          open={openMobileDisclaimer}
          onClose={handleMobileDeviceDisclaimerToogle}
        >
          <MobileDeviceDisclaimer
            handleMobileDeviceDisclaimerToogle={
              handleMobileDeviceDisclaimerToogle
            }
          />
        </Dialog>
        {(user?.email || pathname === "/plans") && (
          <AppBar position="static" sx={{ backgroundColor: "inherit" }}>
            <Toolbar variant="dense">
              <Box
                sx={{
                  mt: 2,
                  flexGrow: 1,
                }}
              >
                {!user?.email && pathname === "/plans" ? (
                  <div
                    onClick={() => {
                      window.location.replace("https://inkwellpr.ai");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={inkwellLogo} alt="Inkwell logo" />
                  </div>
                ) : (
                  <Link to="/">
                    <img src={inkwellLogo} alt="Inkwell logo" />
                  </Link>
                )}
              </Box>
              {user?.email ? (
                <>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    style={{ marginRight: "10px", fontSize: "0.875rem" }}
                  >
                    Welcome{" "}
                    {currentUser?.firstName && currentUser?.lastName
                      ? `${currentUser?.firstName} ${currentUser?.lastName}`
                      : user?.email?.split("@")[0]}
                  </Typography>
                  <Button
                    onClick={handleLogout}
                    sx={{ color: "#555", px: 3, py: 1, fontSize: "0.875rem" }}
                  >
                    Sign out
                  </Button>

                  {subActive ? (
                    pathname !== "/account-settings" && (
                      <Link to="/account-settings">
                        <Button
                          variant="signout"
                          sx={{ color: "#555", px: 3, py: 1 }}
                        >
                          {isLoading ? (
                            <CircularProgress
                              size={20}
                              sx={{ color: "inherit" }}
                            />
                          ) : (
                            "My Account"
                          )}
                        </Button>
                      </Link>
                    )
                  ) : (
                    <Button
                      variant="text"
                      sx={{ px: 3, py: 1 }}
                      onClick={handleSubscribe}
                    >
                      {isLoading ? (
                        <CircularProgress size={20} sx={{ color: "inherit" }} />
                      ) : (
                        "Subscribe"
                      )}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {/* <WhiteButton
                    sx={{ mr: 3, px: "30px", py: "10px" }}
                    onClick={() => openAuthDialog("signup")}
                  >
                    Register
                  </WhiteButton>
                  <Button
                    variant="contained"
                    sx={{ fontWeight: "700", px: "30px", py: "10px" }}
                    onClick={() => openAuthDialog("login")}
                  >
                    Login
                  </Button> */}
                </>
              )}
            </Toolbar>
          </AppBar>
        )}

        <Routes>
          <Route path="/plans" element={<Plans />} />
          <Route exact path="/:id?/:key?" element={<Home />} />
          <Route element={<Private />}>
            <Route path="/writer" element={<Writer />} />
            <Route path="/account-settings" element={<AccountSettings />} />
          </Route>
        </Routes>
      </Container>
    </ThemeProvider>
  );
}
